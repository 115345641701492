<template>
    <div class="container1150">
        <header><strong>深圳光明新区探索建设"海绵城市"</strong></header>
        <main>
            <div class="img1"></div>
            <a
                href="http://gz.house.sina.com.cn/news/2015-07-14/09266026531998670307435.shtml"
                >深圳光明新区探索建设“海绵城市”</a
            >
        </main>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        h2 {
            height: 60px;
            line-height: 60px;
        }
        .img1 {
            // width: 100%;
            width: 550px;
            height: 400px;
            margin: 10px auto;
            background-image: url("/img/news/news1-1.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        a {
            display: block;
            width: 1100px;
            height: 30px;
            font-size: 20px;
            margin: 0 auto;
            line-height: 30px;
            text-align: center;
        }
    }
}
</style>